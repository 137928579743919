<template>
    <fade-up-animation :element="() => $refs.section">
        <div
            class="basic-text-section"
            :class="{ centered }"
            ref="section"
        >
            <b-container>
                <mobile-gutter>
                    <b-row>
                        <b-col
                            :lg="containerColumns"
                            :offset-lg="(12 - containerColumns) / 2"
                            v-test:column
                        >
                            <markdown-content
                                :content="content"
                            />
                        </b-col>
                    </b-row>
                </mobile-gutter>
            </b-container>
        </div>
    </fade-up-animation>
</template>

<script>
    import MarkdownContent from '../../utils/MarkdownContent/MarkdownContent';

    const columnWidths = {
        full_width: 12,
        ten_columns: 10,
        eight_columns: 8,
        six_columns: 6,
    };

    export default {
        name: 'basic-text-section',

        components: { MarkdownContent },

        props: {
            content: {
                type: String,
                required: true,
            },

            containerWidth: {
                type: String,
                required: true,
                validator: (val) => Object.keys(columnWidths)
                    .includes(val),
            },

            centered: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            /**
             * Return the amount of columns for the container.
             *
             * @returns {number}
             */
            containerColumns() {
                return columnWidths[this.containerWidth];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .basic-text-section {
        padding: 34px 0;
        font-size: rem(20px);
    }

    .centered {
        text-align: center;
    }

    .markdown-content ::v-deep {
        p:last-child {
            margin-bottom: 0;
        }
    }
</style>
