<template>
    <basic-text-section
        class="basic-text-section-module"
        :content="attributes.basicText_content"
        :centered="attributes.basicText_centered"
        :container-width="attributes.container_width"
    />
</template>

<script>
    import BasicTextSection from '../../../organisms/BasicTextSection/BasicTextSection';

    export default {
        name: 'basic-text-section-module',

        components: { BasicTextSection },

        props: {
            attributes: {
                type: Object,
                default: () => ({}),
            },
        },
    };
</script>
